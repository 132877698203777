import EditorToolbarButton from '../interfaces/EditorToolbarButton';
import icons from '../icons/icons';
import {Editor} from 'roosterjs-editor-core';
import {FormatState} from 'roosterjs-editor-types';
import {setBackgroundColor, setTextColor} from 'roosterjs-editor-api';

const H_PARTS = 16;
const PART_COLORS = 8;

const HUE_DIFF = 16;

const SAT_START = 60;
const SAT_END = 90;

const LIGHT_START = 85;
const LIGHT_END = 10;

const COLOR_HEIGHT = 16;
const COLOR_WIDTH = 16;

export default class FontColorButton implements EditorToolbarButton {

    private editor: Editor;
    private span: HTMLElement;
    private colorIndicatorSpan: HTMLElement;
    private background: Boolean;

    constructor(editor: Editor, background: Boolean) {
        this.editor = editor;
        this.background = background;
        this.span = this.generateElement();
    }

    getName(): string {
        if (this.background) {
            return 'bgColor';
        } else {
            return 'color';
        }
    }

    getIcon(): string {
        if (this.background) {
            return icons.backgroundColor;
        }
        return icons.fontColor;
    }

    updateState(state: FormatState) {

        if (this.background) {
            this.colorIndicatorSpan.style.backgroundColor = state.backgroundColor;
            return;
        }

        this.colorIndicatorSpan.style.backgroundColor = state.textColor;

    }

    public append(div: HTMLDivElement) {
        div.appendChild(this.span);
    }

    public generateElement(): HTMLSpanElement {

        let span = <HTMLSpanElement>document.createElement('span');
        span.className = 'btn dropdown';
        span.innerHTML = this.getIcon();

        this.colorIndicatorSpan = <HTMLSpanElement>document.createElement('span');
        this.colorIndicatorSpan.style.position = "absolute";
        this.colorIndicatorSpan.style.bottom = "2px";
        this.colorIndicatorSpan.style.display = "block";
        this.colorIndicatorSpan.style.height = "4px"
        this.colorIndicatorSpan.style.left = "0px"
        this.colorIndicatorSpan.style.right = "12px"
        this.colorIndicatorSpan.style.pointerEvents = "none";

        span.append(this.colorIndicatorSpan);

        document.addEventListener('click', (e: MouseEvent) => {
            var ec = e.target as HTMLElement;

            if (this.span.classList.contains('opened') && !this.span.contains(ec)) {
                this.span.classList.remove('opened');
            }
        });

        span.addEventListener('click', (e: MouseEvent) => {
            this.editor.focus();

            if (this.span.classList.contains('opened')) {
                this.span.classList.remove('opened');
            } else {
                this.span.classList.add('opened');
            }
        });

        let optionsDiv = <HTMLDivElement>document.createElement('div');
        optionsDiv.className = 'options';
        optionsDiv.style.paddingLeft = "4px";
        optionsDiv.style.paddingTop = "4px";
        optionsDiv.style.width = (H_PARTS * 20 + 20 + 8) + "px"
        optionsDiv.style.height = (PART_COLORS * 20 + 9) + "px"

        optionsDiv.append(
            this.generatePart(0, 100, 0, 0, 0)
        );

        for (var h = 0; h < H_PARTS; h++) {
            var hue = (360 / H_PARTS) * h;
            optionsDiv.append(
                this.generatePart(hue)
            );
        }

        optionsDiv.addEventListener('click', e => {
            let color = (e.target as HTMLElement).dataset.color;
            if (this.background) {
                setBackgroundColor(this.editor, color);
            } else {
                setTextColor(this.editor, color);
            }
        });

        span.appendChild(optionsDiv);
        return span;
    }

    generatePart(
        hue: number,
        lightStart: number = LIGHT_START, lightEnd: number = LIGHT_END,
        satStart: number = SAT_START, satEnd: number = SAT_END
    ): HTMLElement {

        var col = document.createElement('div');
        col.style.width = (COLOR_WIDTH + 4) + "px"
        col.style.cssFloat = "left";

        for (var i = 0; i < PART_COLORS; i++) {

            var partHue = hue + ((HUE_DIFF / PART_COLORS) * i)
            if (partHue < 0) {
                partHue = 360 + partHue;
            }

            var satStep = (satEnd - satStart) * this.easeLinear(i, PART_COLORS)
            //var satStep = (SAT_END - SAT_START) * this.easeOutCubic(i, PART_COLORS)

            var partSat = satStart + satStep;

            //var partLight = LIGHT_START + ((LIGHT_END - LIGHT_START) / PART_COLORS) * i;
            let partLight = lightStart + ((lightEnd - lightStart) * this.easeLinear(i, PART_COLORS));

            var rgb = FontColorButton.hslToRgb(
                partHue / 360, partSat / 100, partLight / 100
            );

            var em = document.createElement('span');
            em.style.background = 'rgb(' + rgb.join(',') + ')';
            em.style.height = COLOR_HEIGHT + 'px';
            em.style.width = COLOR_WIDTH + 'px';
            em.style.display = "block";
            em.style.marginBottom = "4px"
            em.dataset.color = 'rgb(' + rgb.join(',') + ')';

            col.appendChild(em);

        }

        return col;

    }

    public easeLinear(step: number, numsteps: number): number {
        return step / numsteps;
    }

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number}  h       The hue
     * @param   {number}  s       The saturation
     * @param   {number}  l       The lightness
     * @return  {Array}           The RGB representation
     */
    public static hslToRgb(h: number, s: number, l: number): number[] {

        let r: number;
        let g: number;
        let b: number;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            var hue2rgb = function hue2rgb(p: number, q: number, t: number) {
                if (t < 0) {
                    t += 1;
                }
                if (t > 1) {
                    t -= 1;
                }
                if (t < 1 / 6) {
                    return p + (q - p) * 6 * t;
                }
                if (t < 1 / 2) {
                    return q;
                }
                if (t < 2 / 3) {
                    return p + (q - p) * (2 / 3 - t) * 6;
                }
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }


}
